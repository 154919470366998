import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import Vue from "vue";
import VueApollo from "vue-apollo";
import { onError } from "@apollo/client/link/error";
import { createApolloProvider } from "@vue/apollo-option";
import { provideApolloClient } from "@vue/apollo-composable";
import { useAccessGroupStore } from "~/composables/stores/accessGroup";
import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, from } from "@apollo/client/core";
Vue.use(VueApollo);
export default (function (ctx, inject) {
  var app = ctx.app,
    store = ctx.store;
  var AUTH0_TOKEN = "auth0-token";
  var ERROR_MESSAGE_THIRD_PARTY_ERROR = "ESTAID: THIRD_PARTY_SERVICE_ERROR";
  var accessGroupStore = useAccessGroupStore();

  // HTTP connection to the API
  var httpLink = new HttpLink({
    uri: process.env.gateway || "https://router-test.estaid.dk/query"
  });

  // Middleware to add the authentication token to every request
  var authMiddleware = new ApolloLink(function (operation, forward) {
    var headers = _objectSpread(_objectSpread({}, operation.getContext().headers), {}, {
      authorization: "Bearer ".concat(getAuth0Token() || null)
    });
    var accessGroup = accessGroupStore.accessGroup;
    if (accessGroup) {
      headers["Access-Group-Context"] = accessGroup;
    }
    operation.setContext({
      headers: headers
    });
    return forward(operation);
  });

  // Middleware to add error handling
  var errorMiddleware = onError(function (_ref) {
    var graphQLErrors = _ref.graphQLErrors,
      networkError = _ref.networkError,
      operation = _ref.operation,
      forward = _ref.forward;
    // Clear any third party error flags after 30 minutes
    if (store.getters["errors/isThirdPartyError"]) {
      var lastThirdPartyError = store.getters["errors/lastThirdPartyErrorAt"];
      var maxErrorTime = new Date(Date.now() - 1000 * 60 * 30); // 30 minutes
      if (maxErrorTime > lastThirdPartyError) {
        store.dispatch("errors/setThirdPartyError", false);
      }
    }

    // If the error is a third party error, set the flag
    if (graphQLErrors && graphQLErrors.some(function (e) {
      return e.message.includes(ERROR_MESSAGE_THIRD_PARTY_ERROR);
    })) {
      store.dispatch("errors/setThirdPartyError", true);
    }
    if (networkError) {
      console.error("[Network error]: ".concat(networkError));
    }
  });

  // Options
  var defaultOptions = {
    $query: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore"
    }
  };

  // Cache
  var cache = new InMemoryCache({});

  // Create the apollo client
  var apolloClient = new ApolloClient({
    link: from([authMiddleware, errorMiddleware, httpLink]),
    cache: cache,
    queryDeduplication: false
  });

  // Createt the provider
  var apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
    defaultOptions: defaultOptions
  });

  // Make provider avaialbe on app
  app.apolloProvider = apolloProvider;
  function getAuth0Token() {
    return localStorage.getItem(AUTH0_TOKEN);
  }
  provideApolloClient(apolloClient);
});