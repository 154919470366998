import PlesnerWarnPersonLookup from "~/components/modal/login/PlesnerWarnPersonLookup.vue";

const PLESNER_ORG_ID = "7a51a123-d46f-4d2a-b69a-fabff5dc22a6";

export const getLoginMessage = async (orgId, showForTest, context) => {
    const validMessages = await Promise.all(loginMessages.map(async (m) => await m.isValidForUser(orgId, showForTest, context)));

    return loginMessages.find((m, i) => validMessages[i]);
}

const loginMessages = [
    {
        name: "plesnerPersonLookupMessage",
        component: PlesnerWarnPersonLookup,
        async isValidForUser(orgId, showForTest, context) {
            if (showForTest || orgId === PLESNER_ORG_ID) {
                return await context.$userPreferences.getKey("preferenceNamespaceLoginMessages", "plesnerPersonLookupMessage")
            }

            return false;
        },
    }
]
