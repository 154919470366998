import { render, staticRenderFns } from "./Chrome.vue?vue&type=template&id=2c99f28a"
import script from "./Chrome.vue?vue&type=script&setup=true&lang=ts"
export * from "./Chrome.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Chrome.vue?vue&type=style&index=0&id=2c99f28a&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChromeLogo: require('/app/components/Chrome/components/Logo/ChromeLogo.vue').default,FremiumBar: require('/app/components/Chrome/components/FremiumBar.vue').default,ChromeOverlay: require('/app/components/Chrome/components/Overlay/ChromeOverlay.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default})
