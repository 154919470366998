import type translationsDA from "~/translations/da.json";
import type translationsEN from "~/translations/en.json";
import { type Units, convertArea as turfConvertArea } from "@turf/helpers";

export const useThrottle = (func: (...args: any) => any, limit: number) => {
  let inThrottle: boolean = false;

  return function (this: any, ...args: any[]) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const hasValue = <T extends unknown>(v: T): v is NonNullable<T> => typeof v !== "undefined" && v !== null;

class NaNError extends Error {
  message: string = "Not a number";
}

export const prepareNumber = (number: Nullable<number | string>) => {
  if (!hasValue(number) || number === Infinity || number === -Infinity) throw new NaNError();

  if (typeof number === "string") number = parseFloat(number);

  if (isNaN(number)) throw new NaNError();

  return number;
};

export const formatString = (string: string | null | undefined, formatter: (string: string) => string) => {
  if (!hasValue(string)) return "–";

  return formatter(string);
};

export const formatNumber = (number: number | string | null | undefined, formatter: (number: number) => string) => {
  try {
    return formatter(prepareNumber(number));
  } catch (e) {
    if (e instanceof NaNError) return "–";
    else throw e;
  }
};

export type TranslationKey = keyof typeof translationsDA & keyof typeof translationsEN;

export type UIColor = `--${string}-${"white" | `${string}-${number}`}`;

const areaUnitsTurfMap: { [k: string]: Units } = {
  m2: "meters",
  ft2: "feet",
};

export const convertArea = (area: Nullable<number | string>, sourceUnit: Nullable<string>, targetUnit: Nullable<string>) => {
  if (!sourceUnit || !targetUnit) {
    if (area) throw `missing area units, sourceUnit: ${sourceUnit}, targetUnit: ${targetUnit}, area: ${area}`;
    else return 0;
  }

  const turfSourceUnit = areaUnitsTurfMap[sourceUnit];
  const turfTargetUnit = areaUnitsTurfMap[targetUnit];

  if (area && (!turfSourceUnit || !turfTargetUnit)) throw `unsupported area units sourceUnit: ${sourceUnit}, targetUnit: ${targetUnit}`;

  try {
    return turfConvertArea(prepareNumber(area), turfSourceUnit, turfTargetUnit);
  } catch {
    return 0;
  }
};
