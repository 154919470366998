/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 11
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/estaid/Estaid/implementation/web)
 */

import * as amplitude from "@amplitude/analytics-browser";

export type Environment = "production";

export const ApiKey: Record<Environment, string> = {
  production: "bc4c2153f9ae427650297510cd54c0e7",
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: "11",
    branch: "main",
    source: "web",
    versionId: "5f501f45-ba0a-4d41-8460-4af47d188d67",
  },
  ...{
    ingestionMetadata: {
      sourceName: "browser-typescript-ampli",
      sourceVersion: "2.0.0",
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions } };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  orgId?: any;
  orgName?: any;
  orgRole?: string;
}

export interface DownloadAndVerifyEasementProperties {
  /**
   * The unique ID for a given act containing easements
   */
  actID: string;
  /**
   * Dato/løbenummer, the ID for an easement
   */
  easementID: string;
  /**
   * Number of pages marked in servitutfinder
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  pagesMarked: number;
}

export interface ExploreProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  activeFilters: string[];
}

export interface ExportUnitsProperties {
  filterId?: string;
  filterName?: string;
}

export interface NavigateToTabProperties {
  /**
   * The "Hierarchy" property indicates the specific level in our system, which can be one of the following values: "Portfolio," "Property," or "Unit." It helps categorize the context of the user's interaction.
   */
  hierarchy?: any;
  /**
   * The "Page" property specifies which tab a user has navigated to within our system. It can have values like "Overview," "Capex," "Opex," or "Trial balance," providing insights into the user's focus or area of interest during navigation.
   */
  page?: any;
}

export interface OpenAccountProperties {
  accountId: string;
  accountName: string;
  accountNumber: string;
  categoryId: string;
  categoryName: string;
  periodLabel: string;
  periodType: string;
  periodValue: string;
  propertyId: string;
}

export interface OpenCategoryXPropertyProperties {
  categoryId: string;
  categoryName: string;
  propertyId: string;
}

export interface OpenSavedRentRollProperties {
  savedFilterId: string;
  savedFilterName: string;
}

export interface OpenServitutfinderProperties {
  postalCode?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | main_property, condo, building_on_foreign_plot, unknown |
   */
  propertyType: "main_property" | "condo" | "building_on_foreign_plot" | "unknown";
}

export interface OpenBalanceAccountProperties {
  accountId: string;
  accountName: string;
  accountNumber: string;
  periodLabel: string;
  periodType: string;
  periodValue: string;
}

export interface ResetPasswordProperties {
  message: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SUCCESS, ERROR |
   */
  status?: "SUCCESS" | "ERROR";
}

export interface SetFilterProperties {
  /**
   * Specifies the name of the filter applied by the user.
   */
  filter?: string;
  /**
   * Identifies the particular field or attribute within the filter that the user interacted with, offering detailed information on the applied filter configuration.
   */
  filterField?: string;
}

export interface SetFilterPropertyRentalLevelsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  Filters?: string[];
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AreaSearchLejetjek implements BaseEvent {
  event_type = "Area search - Lejetjek";
}

export class ChangeAct implements BaseEvent {
  event_type = "Change act";
}

export class CloseServitutfinder implements BaseEvent {
  event_type = "Close servitutfinder";
}

export class DownloadAndVerifyEasement implements BaseEvent {
  event_type = "Download and verify easement";

  constructor(public event_properties: DownloadAndVerifyEasementProperties) {
    this.event_properties = event_properties;
  }
}

export class Explore implements BaseEvent {
  event_type = "Explore";

  constructor(public event_properties: ExploreProperties) {
    this.event_properties = event_properties;
  }
}

export class ExportBalance implements BaseEvent {
  event_type = "Export balance";
}

export class ExportUnits implements BaseEvent {
  event_type = "Export units";

  constructor(public event_properties?: ExportUnitsProperties) {
    this.event_properties = event_properties;
  }
}

export class FollowRentRoll implements BaseEvent {
  event_type = "Follow rent roll";
}

export class GetLeases implements BaseEvent {
  event_type = "Get leases";
}

export class HelpButton implements BaseEvent {
  event_type = "Help button";
}

export class LejetjekNoAccessDetails implements BaseEvent {
  event_type = "Lejetjek NoAccess Details";
}

export class LejetjekNoAccessUpgrade implements BaseEvent {
  event_type = "Lejetjek NoAccess Upgrade";
}

export class LokalebasenNoAccessDetails implements BaseEvent {
  event_type = "Lokalebasen NoAccess Details";
}

export class LokalebasenNoAccessUpgrade implements BaseEvent {
  event_type = "Lokalebasen NoAccess Upgrade";
}

export class NavigateToExplore implements BaseEvent {
  event_type = "Navigate to explore";
}

export class NavigateToPortfolio implements BaseEvent {
  event_type = "Navigate to portfolio";
}

export class NavigateToProperties implements BaseEvent {
  event_type = "Navigate to properties";
}

export class NavigateToRentRoll implements BaseEvent {
  event_type = "Navigate to rent roll";
}

export class NavigateToTab implements BaseEvent {
  event_type = "Navigate to tab";

  constructor(public event_properties?: NavigateToTabProperties) {
    this.event_properties = event_properties;
  }
}

export class NextEasement implements BaseEvent {
  event_type = "Next easement";
}

export class OpenAccount implements BaseEvent {
  event_type = "Open account";

  constructor(public event_properties: OpenAccountProperties) {
    this.event_properties = event_properties;
  }
}

export class OpenCategoryXProperty implements BaseEvent {
  event_type = "Open category x property";

  constructor(public event_properties: OpenCategoryXPropertyProperties) {
    this.event_properties = event_properties;
  }
}

export class OpenSavedRentRoll implements BaseEvent {
  event_type = "Open saved rent roll";

  constructor(public event_properties: OpenSavedRentRollProperties) {
    this.event_properties = event_properties;
  }
}

export class OpenServitutfinder implements BaseEvent {
  event_type = "Open servitutfinder";

  constructor(public event_properties: OpenServitutfinderProperties) {
    this.event_properties = event_properties;
  }
}

export class OpenBalanceAccount implements BaseEvent {
  event_type = "openBalanceAccount";

  constructor(public event_properties: OpenBalanceAccountProperties) {
    this.event_properties = event_properties;
  }
}

export class PreviousEasement implements BaseEvent {
  event_type = "Previous easement";
}

export class ResetPassword implements BaseEvent {
  event_type = "Reset password";

  constructor(public event_properties: ResetPasswordProperties) {
    this.event_properties = event_properties;
  }
}

export class SaveRentRoll implements BaseEvent {
  event_type = "Save rent roll";
}

export class SetFilter implements BaseEvent {
  event_type = "Set filter";

  constructor(public event_properties?: SetFilterProperties) {
    this.event_properties = event_properties;
  }
}

export class SetFilterPropertyRentalLevels implements BaseEvent {
  event_type = "Set filter - property-rental-levels";

  constructor(public event_properties?: SetFilterPropertyRentalLevelsProperties) {
    this.event_properties = event_properties;
  }
}

export class UnfollowRentRoll implements BaseEvent {
  event_type = "Unfollow rent roll";
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Area search - Lejetjek
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Area%20search%20-%20Lejetjek)
   *
   * Owner: Mikkel Højland
   *
   * @param options Amplitude event options.
   */
  areaSearchLejetjek(
    options?: EventOptions,
  ) {
    return this.track(new AreaSearchLejetjek(), options);
  }

  /**
   * Change act
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Change%20act)
   *
   * In servitutfinder, change the viewed act.
   *
   * Owner: Mikkel Højland
   *
   * @param options Amplitude event options.
   */
  changeAct(
    options?: EventOptions,
  ) {
    return this.track(new ChangeAct(), options);
  }

  /**
   * Close servitutfinder
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Close%20servitutfinder)
   *
   * Click the cross in the upper right corner of servitutfinder
   *
   * Owner: Mikkel Højland
   *
   * @param options Amplitude event options.
   */
  closeServitutfinder(
    options?: EventOptions,
  ) {
    return this.track(new CloseServitutfinder(), options);
  }

  /**
   * Download and verify easement
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Download%20and%20verify%20easement)
   *
   * Click the download and verify button in servitutfinder
   *
   * Owner: Mikkel Højland
   *
   * @param properties The event's properties (e.g. actID)
   * @param options Amplitude event options.
   */
  downloadAndVerifyEasement(
    properties: DownloadAndVerifyEasementProperties,
    options?: EventOptions,
  ) {
    return this.track(new DownloadAndVerifyEasement(properties), options);
  }

  /**
   * Explore
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Explore)
   *
   * Owner: Mikkel Højland
   *
   * @param properties The event's properties (e.g. activeFilters)
   * @param options Amplitude event options.
   */
  explore(
    properties: ExploreProperties,
    options?: EventOptions,
  ) {
    return this.track(new Explore(properties), options);
  }

  /**
   * Export balance
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Export%20balance)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  exportBalance(
    options?: EventOptions,
  ) {
    return this.track(new ExportBalance(), options);
  }

  /**
   * Export units
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Export%20units)
   *
   * This event tracks the number of times units are exported from our system.
   *
   * @param properties The event's properties (e.g. filterId)
   * @param options Amplitude event options.
   */
  exportUnits(
    properties?: ExportUnitsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportUnits(properties), options);
  }

  /**
   * Follow rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Follow%20rent%20roll)
   *
   * This event tracks when a user follows a specific rent roll
   *
   * @param options Amplitude event options.
   */
  followRentRoll(
    options?: EventOptions,
  ) {
    return this.track(new FollowRentRoll(), options);
  }

  /**
   * Get leases
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Get%20leases)
   *
   * Owner: sga@estaid.dk
   *
   * @param options Amplitude event options.
   */
  getLeases(
    options?: EventOptions,
  ) {
    return this.track(new GetLeases(), options);
  }

  /**
   * Help button
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Help%20button)
   *
   * Click the help button in the upper right corner of servitut finder
   *
   * Owner: Mikkel Højland
   *
   * @param options Amplitude event options.
   */
  helpButton(
    options?: EventOptions,
  ) {
    return this.track(new HelpButton(), options);
  }

  /**
   * Lejetjek NoAccess Details
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Lejetjek%20NoAccess%20Details)
   *
   * Owner: tc@estaid.dk
   *
   * @param options Amplitude event options.
   */
  lejetjekNoAccessDetails(
    options?: EventOptions,
  ) {
    return this.track(new LejetjekNoAccessDetails(), options);
  }

  /**
   * Lejetjek NoAccess Upgrade
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Lejetjek%20NoAccess%20Upgrade)
   *
   * Owner: tc@estaid.dk
   *
   * @param options Amplitude event options.
   */
  lejetjekNoAccessUpgrade(
    options?: EventOptions,
  ) {
    return this.track(new LejetjekNoAccessUpgrade(), options);
  }

  /**
   * Lokalebasen NoAccess Details
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Lokalebasen%20NoAccess%20Details)
   *
   * Owner: tc@estaid.dk
   *
   * @param options Amplitude event options.
   */
  lokalebasenNoAccessDetails(
    options?: EventOptions,
  ) {
    return this.track(new LokalebasenNoAccessDetails(), options);
  }

  /**
   * Lokalebasen NoAccess Upgrade
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Lokalebasen%20NoAccess%20Upgrade)
   *
   * Owner: tc@estaid.dk
   *
   * @param options Amplitude event options.
   */
  lokalebasenNoAccessUpgrade(
    options?: EventOptions,
  ) {
    return this.track(new LokalebasenNoAccessUpgrade(), options);
  }

  /**
   * Navigate to explore
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20explore)
   *
   * Owner: Mikkel Højland
   *
   * @param options Amplitude event options.
   */
  navigateToExplore(
    options?: EventOptions,
  ) {
    return this.track(new NavigateToExplore(), options);
  }

  /**
   * Navigate to portfolio
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20portfolio)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  navigateToPortfolio(
    options?: EventOptions,
  ) {
    return this.track(new NavigateToPortfolio(), options);
  }

  /**
   * Navigate to properties
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20properties)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  navigateToProperties(
    options?: EventOptions,
  ) {
    return this.track(new NavigateToProperties(), options);
  }

  /**
   * Navigate to rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20rent%20roll)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  navigateToRentRoll(
    options?: EventOptions,
  ) {
    return this.track(new NavigateToRentRoll(), options);
  }

  /**
   * Navigate to tab
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20tab)
   *
   * This event records when a user navigates between tabs at different levels, such as Portfolio, Property, or Unit levels, within our system.
   *
   * @param properties The event's properties (e.g. hierarchy)
   * @param options Amplitude event options.
   */
  navigateToTab(
    properties?: NavigateToTabProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavigateToTab(properties), options);
  }

  /**
   * Next easement
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Next%20easement)
   *
   * Navigate to next easement in servitutfinder
   *
   * Owner: Mikkel Højland
   *
   * @param options Amplitude event options.
   */
  nextEasement(
    options?: EventOptions,
  ) {
    return this.track(new NextEasement(), options);
  }

  /**
   * Open account
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Open%20account)
   *
   * The user clicked an account in the opex table modal, to get a list of all financial records for a period
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  openAccount(
    properties: OpenAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenAccount(properties), options);
  }

  /**
   * Open category x property
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Open%20category%20x%20property)
   *
   * The user clicked on a datapoint in the opex table
   *
   * Owner: Sven Buskov Romme
   *
   * @param properties The event's properties (e.g. categoryId)
   * @param options Amplitude event options.
   */
  openCategoryXProperty(
    properties: OpenCategoryXPropertyProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenCategoryXProperty(properties), options);
  }

  /**
   * Open saved rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Open%20saved%20rent%20roll)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. savedFilterId)
   * @param options Amplitude event options.
   */
  openSavedRentRoll(
    properties: OpenSavedRentRollProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenSavedRentRoll(properties), options);
  }

  /**
   * Open servitutfinder
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Open%20servitutfinder)
   *
   * The "Find in act" button on a servitut in https://app.estaid.dk/explore/property/6014180/easements
   *
   * Owner: Mikkel Højland
   *
   * @param properties The event's properties (e.g. postalCode)
   * @param options Amplitude event options.
   */
  openServitutfinder(
    properties: OpenServitutfinderProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenServitutfinder(properties), options);
  }

  /**
   * openBalanceAccount
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/openBalanceAccount)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  openBalanceAccount(
    properties: OpenBalanceAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenBalanceAccount(properties), options);
  }

  /**
   * Previous easement
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Previous%20easement)
   *
   * Navigate to the previous easement in servitutfinder
   *
   * Owner: Mikkel Højland
   *
   * @param options Amplitude event options.
   */
  previousEasement(
    options?: EventOptions,
  ) {
    return this.track(new PreviousEasement(), options);
  }

  /**
   * Reset password
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Reset%20password)
   *
   * Owner: Morten Esbensen
   *
   * @param properties The event's properties (e.g. message)
   * @param options Amplitude event options.
   */
  resetPassword(
    properties: ResetPasswordProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResetPassword(properties), options);
  }

  /**
   * Save rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Save%20rent%20roll)
   *
   * This event tracks when a user saves filters for the rent roll view
   *
   * @param options Amplitude event options.
   */
  saveRentRoll(
    options?: EventOptions,
  ) {
    return this.track(new SaveRentRoll(), options);
  }

  /**
   * Set filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Set%20filter)
   *
   * Records instances when users apply filters, providing insights into how different filters are utilized.
   *
   * @param properties The event's properties (e.g. filter)
   * @param options Amplitude event options.
   */
  setFilter(
    properties?: SetFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetFilter(properties), options);
  }

  /**
   * Set filter - property-rental-levels
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Set%20filter%20-%20property-rental-levels)
   *
   * Owner: sga@estaid.dk
   *
   * @param properties The event's properties (e.g. Filters)
   * @param options Amplitude event options.
   */
  setFilterPropertyRentalLevels(
    properties?: SetFilterPropertyRentalLevelsProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetFilterPropertyRentalLevels(properties), options);
  }

  /**
   * Unfollow rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Unfollow%20rent%20roll)
   *
   * This event tracks when a user unfollows a specific rent roll
   *
   * @param options Amplitude event options.
   */
  unfollowRentRoll(
    options?: EventOptions,
  ) {
    return this.track(new UnfollowRentRoll(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
