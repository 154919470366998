
import Cta from "./components/Cta.vue";
import { mapState } from "pinia";

export default {
  components: { Cta },

  props: {
    label: {
      type: String,
      default: "",
    },

    byline: {
      type: String,
      default: "",
    },

    colorBackground: {
      type: String,
      default: "--color-red-900",
    },

    colorForeground: {
      type: String,
      default: "--color-red-900",
    },

    omitPadding: {
      type: Boolean,
      default: false,
    },

    omitPaddingTop: {
      type: Boolean,
      default: false,
    },

    omitPaddingRight: {
      type: Boolean,
      default: false,
    },

    omitPaddingBottom: {
      type: Boolean,
      default: false,
    },

    omitPaddingLeft: {
      type: Boolean,
      default: false,
    },

    omitPaddingContent: {
      type: Boolean,
      default: false,
    },

    justifyTextsCenter: {
      type: Boolean,
      default: false,
    },

    justifyContentCenter: {
      type: Boolean,
      default: false,
    },

    justifyContentEnd: {
      type: Boolean,
      default: false,
    },

    alignItemsCenter: {
      type: Boolean,
      default: false,
    },

    alignItemsEnd: {
      type: Boolean,
      default: false,
    },

    paddingDouble: {
      type: Boolean,
      default: false,
    },

    loadingEnabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    omitClickMore: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    row: {
      type: Boolean,
      default: false,
    },

    clickMoreLabel: {
      type: String,
      default: undefined,
    },

    /*
     * None, small, medium, large
     */
    shadow: {
      type: String,
      default: "none",
    },
  },

  methods: {
    slotContainerPopulated() {
      return !!this.$slots && !!this.$slots.container;
    },

    slotControlsPopulated() {
      return !!this.$slots && !!this.$slots.controls;
    },
  },
};
