import Vue from "vue";
import VueI18n from "vue-i18n";

import ENGLISH_TRANSLATIONS from "~/translations/en.json";
import DANISH_TRANSLATIONS from "~/translations/da.json";
import DEV_TRANSLATIONS from "~/translations/dev.json";

Vue.use(VueI18n);

const LANGUAGE_DEFAULT = "da";

const TRANSLATIONS = {
  "en-gb": ENGLISH_TRANSLATIONS,
  da: DANISH_TRANSLATIONS,
  dev: DEV_TRANSLATIONS,
};

const preferredLanguage = getNavLanguage() || getUserLanguage() || LANGUAGE_DEFAULT;

const i18n = new VueI18n({
  locale: preferredLanguage,
  silentTranslationWarn: true,
  messages: TRANSLATIONS,
  load: "currentOnly",
});

export default async ({ app }) => {
  app.i18n = i18n;
};

export { i18n };

function getNavLanguage() {
  let navLang = navigator.language;
  if (navLang && (navLang.startsWith("en-gb") || navLang.startsWith("en-US"))) {
    return  "en-gb";
  }

  return LANGUAGE_DEFAULT;
}

function getUserLanguage() {
  let userLang = navigator.userLanguage;
  if (userLang && (userLang.startsWith("en-gb") || userLang.startsWith("en-US"))) {
    return "en-gb";
  }

  return LANGUAGE_DEFAULT;
}
