const state = () => ({
  lastSearches: localStorage.getItem("last-searches") ? JSON.parse(localStorage.getItem("last-searches")) : [],
  searchTerm: null,
});

const getters = {
  lastSearches(state) {
    //Backwards compatibility check. If localstorage contains old searches, remove those
    if (!Array.isArray(state.lastSearches) || state.lastSearches.some((x) => !x.search)) {
      state.lastSearches = [];
      localStorage.setItem("last-searches", []);
    }

    //Backwards compatibility check. If localstorage contains old searches with non id searches remove those
    if (!Array.isArray(state.lastSearches) || state.lastSearches.some((x) => !x.search.id)) {
      state.lastSearches = [];
      localStorage.setItem("last-searches", []);
    }

    return state.lastSearches;
  },
  searchTerm(state) {
    return state.searchTerm;
  },
  matrikel() {
    return $nuxt?.$route?.params?.id?.split("-")[1] || null;
  },
  ejerlav() {
    return parseInt($nuxt?.$route?.params?.id?.split("-")[0], 10) || null;
  },
  bfeNumber() {
    return parseInt($nuxt?.$route?.params?.id) || null;
  },
};

const actions = {
  initiateAddressSearch({ commit }, search) {
    const payload = { ...search, type: "STREET" };
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },

  initiateCadastralSearch({ commit }, search) {
    const payload = { ...search, type: "PLOT" };
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },

  initiateDawaCadastralSearch({ commit }, search) {
    const payload = { ...search, type: "CADASTRAL" };
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },

  initiateBFESearch({ commit }, search) {
    const payload = { ...search, type: "BFE" };
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },

  initiateCompanySearch({ commit }, search) {
    const payload = { ...search, type: "COMPANY" };
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },

  initiatePersonSearch({ commit }, search) {
    const payload = { ...search, type: "PERSON" };
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },

  initiateListSearch({ commit }, search) {
    const payload = { ...search, type: "LIST" };
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },

  initiateHistorySearch({ commit }, payload) {
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },
  initiateHistorySearch({ commit }, payload) {
    commit("search", payload);
    if (payload.save) {
      commit("pushToLastSearches", payload);
    }
    if (payload.track) {
      commit("trackSearch", payload);
    }
  },

  removeFromLastSearches({ commit }, index) {
    commit("removeFromLastSearches", index);
  },

  clearState({ commit }) {
    commit("clearState");
    /* TO-DO: cllpse: implementing this function. This was called, but I'm not sure what should happen, so leaving this as-is for now. Perhaps Luppilau knows what's up here */
  },
};

const mutations = {
  search(state, payload) {
    let params;
    let name;

    if (payload.type == "COMPANY") {
      name = "explore-company-id-overview";
      params = { id: `${payload.search?.id}` };
    } else if (payload.type == "PLOT" || payload.type == "STREET" || payload.type == "BFE") {
      name = "explore-property-id-overview";
      params = { id: `${payload.search?.id}` };
    } else if (payload.type == "CADASTRAL") {
      name = "explore-property-id-overview";
      params = { id: `${payload.search?.ejerlav}-${payload.search?.matrikel}` };
    } else if (payload.type == "PERSON") {
      name = "explore-person-id-ownership";
      params = { id: `${payload.search?.id}` };
    } else if (payload.type == "LIST") {
      name = "my-estaid-lists-id";
      params = { id: `${payload.search?.id}` };
    }

    state.searchTerm = payload.search?.searchTerm;
    $nuxt.$router.push({ name, params });
  },

  trackSearch(state, payload) {
    let eventName;
    let eventProperties;
    if (payload.type == "COMPANY") {
      eventName = "Company lookup";
      eventProperties = {
        cvr: payload.search.id,
        name: payload.search.searchTerm,
        route: payload.track,
      };
    } else if (payload.type == "PERSON") {
      eventName = "Person lookup";
      eventProperties = {
        cvr: payload.search.id,
        name: payload.search.searchTerm,
        route: payload.track,
      };
    } else if (payload.type == "LIST") {
      eventName = "List lookup";
      eventProperties = {
        cvr: payload.search.id,
        name: payload.search.searchTerm,
        route: payload.track,
      };
    } else {
      eventName = "Property lookup";
      eventProperties = {
        bfe: payload.search.id,
        route: payload.track,
      };
    }

    $nuxt.$amplitude.track({ event_type: eventName, event_properties: eventProperties });
  },

  pushToLastSearches(state, search) {
    if (!search) {
      return;
    }
    // if no localstorage is set, create new array in local storage
    if (state.lastSearches == null) {
      state.lastSearches = [search];
      localStorage.setItem("last-searches", JSON.stringify(state.lastSearches));
      return;
    }

    let temp = [...state.lastSearches];
    const placement = temp.findIndex((srch) =>
      Object.keys(srch.search).every((key) => {
        return srch.search[key] === search.search[key];
      })
    );
    if (placement >= 0) {
      temp.splice(placement, 1);
    }
    temp.unshift(search);
    temp = temp.slice(0, 5);
    state.lastSearches = temp;
    localStorage.setItem("last-searches", JSON.stringify(temp));
  },

  removeFromLastSearches(state, index) {
    if (!state.lastSearches) {
      return;
    }
    let temp = [...state.lastSearches];
    temp.splice(index, 1);
    state.lastSearches = temp;
    localStorage.setItem("last-searches", JSON.stringify(temp));
  },
  clearState(state) {
    state.searchTerm = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
