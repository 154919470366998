import { type PropertyAreasQuery } from "graphql/generated/graphql";
import { getAddressAsLongString } from "~/helpers/address-helpers.js";

type Property = NonNullable<PropertyAreasQuery["propertyByBFENumber"]>;
type Plots = Property["plots"];
type PlotTechFacilities = NonNullable<NonNullable<Plots>[number]>["technicalFacilities"];
type Buildings = NonNullable<NonNullable<Plots>[number]>["buildings"];
type BuildingUnits = NonNullable<NonNullable<Buildings>[number]>["units"];

export const mapPlots = function (plots: Plots) {
  return plots?.map((x) => {
    if (!x) return x;

    const o = {
      ...x,
      matEjerlav: `${x.matrikelNumber}, ${x.ownersGuildName}`,
      noOfBuildings: x.buildings?.length,
      noOfUnits: x.buildings?.reduce((a, b) => a + (b?.units ? b.units.length : 0), 0),
      noOfTechnicalFacilities: x.technicalFacilities?.length,
    };

    return o;
  });
};

export const mapBuildings = function (buildings: Buildings, matEjerlav?: string) {
  return buildings?.map((y) => {
    if (!y) return y;

    const o: Object = {
      ...y,
      matEjerlav,
      addressText: getAddressAsLongString(y.address),
      noOfFloors: y.floors?.length,
      energyLabelClassification: y.energyLabel?.classification,
      totalBasementArea: y.floors?.reduce((a, f) => {
        if (!f) return 0;

        return a + ((f.floorTypeText === "Kælder" ? f.floorArea : f.basementArea) ?? 0);
      }, 0),
      usedBasementArea: y.floors?.reduce((a, f) => {
        if (!f) return 0;

        return a + (f.commercialBasementArea ?? 0) + (f.residentialBasementArea ?? 0);
      }, 0),
      deepBasementArea: y.floors?.reduce((a, f) => {
        if (!f) return 0;

        return a + (f.commercialBasementArea === null && f.residentialBasementArea === null ? f.basementArea ?? 0 : 0);
      }, 0),
      totalAtticArea: y.floors?.reduce((a, f) => {
        if (!f) return 0;

        return a + (f.floorTypeText == "Tagetage" ? f.floorArea ?? 0 : f.utilizedAtticArea ?? 0);
      }, 0),
      usedAtticArea: y.floors?.reduce((a, f) => a + (f?.utilizedAtticArea ?? 0), 0),
      totalOtherArea:
        (y.garbageRoomInTerrain ?? 0) +
        (y.garageArea ?? 0) +
        (y.carportArea ?? 0) +
        (y.builtinOuthouseArea ?? 0) +
        (y.enclosedShelterOuthouseArea ?? 0) +
        (y.outhouseArea ?? 0) +
        (y.exteriorReinsulationArea ?? 0) +
        (y.otherArea ?? 0),
      legalBasementResidence: y.floors?.reduce((a, f) => a + (f?.residentialBasementArea ?? 0), 0),
      commercialBasementArea: y.floors?.reduce((a, f) => a + (f?.commercialBasementArea ?? 0), 0),
      floorsWithoutBasementAndAttic: y.floors?.reduce((a, f) => {
        if (!f) return 0;

        return a + (f.floorTypeText != "Tagetage" && f.floorTypeText != "Kælder" ? 1 : 0);
      }, 0),
      totalResidentialArea: y.totalUsableArea,
    };

    return o;
  });
};

export const mapUnits = function (units: BuildingUnits, matEjerlav?: string, buildingNumber?: number) {
  return units?.map((z) => {
    if (!z) return z;

    const o = {
      ...z,
      addressText: getAddressAsLongString(z.address),
      matEjerlav,
      buildingNumber,
    };

    return o;
  });
};

export const mapTechnicalFacilities = function (technicalFacilities: PlotTechFacilities, matEjerlav?: string) {
  return technicalFacilities?.map((y) => {
    const o = {
      ...y,
      typeApprovalNr: "??",
      matEjerlav,
    };

    return o;
  });
};

export const Operator = {
  Equals: "eq",
  GreaterThan: "gt",
  GreaterThanOrEqual: "gte",
  LessThan: "lt",
  LessThanOrEqual: "lte",
  In: "in",
  Contains: "ct",
  ContainsCaseInsensitive: "ict",
  StartsWith: "sw",
  StartsWithCaseInsensitive: "isw",
  EndsWith: "ew",
  EndsWithCaseInsensitive: "iew",
} as const;
