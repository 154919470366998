import BfeForAddress from "../graphql/Property/BfeForCadastral.gql";

// This middleware is responsible for checking and processing addressIds in the URL
export default function ({ app, route, redirect }) {
    const path = route.fullPath.split("/");
    const cadastralRegEx = /^([0-9]{4,7}[-][0-9a-z]{1,5})/;

    function redirectToHome() {
        redirect("/explore");
    }

    function redirectToProperty(bfeNumber) {
        redirect(`/explore/property/${bfeNumber}`);
    }

    // if using old link structure (app.estaid.dk/{mat-ejerlav}), second element in path array will be mat+ejerlav
    let oldLinkStructure = path.length > 1 && cadastralRegEx.test(path[1]);

    // if using new link structure but with cadastral info (app.estaid.dk/explore/property/{mat-ejerlav}), fourth element in path array will be mat+ejerlav
    let cadastralStructure = path.length > 3 && cadastralRegEx.test(path[3]);

    // if using old no addres structure
    let oldNoAddressStructure = path.length > 1 && path[1] === "no-address";

    // if regex matches, fetch the bfe number then reroute to new link structure
    if (oldLinkStructure || cadastralStructure) {
        let cadastralInfo = path[oldLinkStructure ? 1 : 3].split("-");
        return app.apolloProvider.clients.defaultClient
            .query({
                query: BfeForAddress,
                variables: {
                    CadastralDist: parseInt(cadastralInfo[0], 10),
                    Cadastral: decodeURI(cadastralInfo[1]),
                },
            })
            .then((result) => {
                if (result.data.propertyByCadastral && result.data.propertyByCadastral.bfeNumber) {
                    redirectToProperty(result.data.propertyByCadastral.bfeNumber);
                } else {
                    redirectToHome();
                }
            })
            .catch((error) => {
                console.error("Error fetching bfe number", error);
                redirectToHome();
            });
    } else if (oldNoAddressStructure) {
        redirect("/explore");
    } else if (path[1] === "company") {
        redirect(`/explore${route.fullPath}`);
    }
    return;
}
