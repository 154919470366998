
import { DRAWMODE } from "~/store/Constants.js";

import EntityCard from "~/components/EntityCard/components/EntityCard.vue";

import "mapbox-gl/dist/mapbox-gl.css";
import "v-mapbox/dist/v-mapbox.css";
import proj4 from "proj4";

// Layers
import CommonMapLayer from "./layers/CommonMapLayer.vue";
import PropertyMapLayer from "~/components/map/layers/PropertyMapLayer.vue";
import MapLayerPropertyRentLevels from "~/components/map/layers/MapLayerPropertyRentLevels.vue";
import PersonMapLayer from "~/components/map/layers/PersonMapLayer.vue";
import CompanyMapLayer from "~/components/map/layers/CompanyMapLayer.vue";
import ListMapLayer from "~/components/map/layers/ListMapLayer.vue";
import ReferencePropertyMapLayer from "~/components/map/layers/ReferencePropertyMapLayer.vue";
import NativeLayer from "./layers/basicLayers/NativeLayer.vue";

// Windows
import BuildingWindow from "~/components/map/windows/BuildingWindow";
import ExploreWindow from "~/components/map/windows/ExploreWindow";
import FacilityWindow from "~/components/map/windows/FacilityWindow";
import PropertyWindow from "~/components/map/windows/PropertyWindow";
import AreaWindow from "~/components/map/windows/AreaWindow";

// Controls
import MapLayerControls from "./controls/MapLayerControls.vue";
import MapLayerPositions from "./controls/MapLayerPositions.vue";
import MapLegendWMS from "./controls/MapLegendWMS";
import MapDrawManager from "./controls/MapDrawManager";

import StreetView from "./StreetView.vue";

import EstaidMapBoxEmptyState from "./EstaidMapBoxEmptyState.vue";
import { MglMap, MglPopup } from "~/mapbox.js";
import { ResizeSensor } from "css-element-queries";
import { mapSetup } from "./MapBoxConfig.js";
import { mapboxLayers } from "./mapboxLayers";

export default {
  components: {
    MglMap,
    MglPopup,
    CommonMapLayer,
    MapLayerPositions,
    PropertyMapLayer,
    MapLayerPropertyRentLevels,
    PersonMapLayer,
    ReferencePropertyMapLayer,
    CompanyMapLayer,
    ListMapLayer,
    EstaidMapBoxEmptyState,
    BuildingWindow,
    ExploreWindow,
    FacilityWindow,
    PropertyWindow,
    AreaWindow,
    NativeLayer,
    MapLayerControls,
    StreetView,
    MapLegendWMS,
    MapDrawManager,
    EntityCard,
  },

  data() {
    return {
      accessToken: process.env.mapboxToken,
      mapStyle: process.env.mapboxStyle,
      mapIsLoading: false,
      streetViewBounds: null,
      popupCoordinates: [12.507204169575402, 55.693001590178284],
      popupVisible: false,
      tooltips: [],
    };
  },

  computed: {
    maptype: {
      get() {
        return this.$store.getters["mapstate/maptype"];
      },
      set(val) {
        this.$store.commit("mapstate/maptype", val);
      },
    },

    satellite: {
      get() {
        return this.$store.getters["mapstate/showOrtoForaar"];
      },
      set(val) {
        this.$store.commit("mapstate/showOrtoForaar", val);
      },
    },
    mapIsReady: {
      get() {
        return this.$store.getters["mapstate/getMapIsReady"];
      },
      set(val) {
        this.$store.commit("mapstate/setMapIsReady", val);
      },
    },

    drawingEnabled: function () {
      return this.$store.getters["mapstate/getDrawingEnabled"];
    },

    routeName() {
      return this.$route.name;
    },

    ...mapboxLayers
      .flatMap((mbl) => mbl.layer)
      .reduce((acc, layer) => {
        acc[layer.id] = {
          get() {
            return this.$store.getters[`mapstate/${layer.id}`];
          },
        };
        return acc;
      }, {}),

    legends() {
      return mapboxLayers
        .flatMap((mbl) => mbl.layer)
        .filter((layer) => this[layer.id])
        .filter((layer) => layer.hasLegend)
        .map((layer) => layer.legend);
    },

    legendVisible() {
      return (
        this.$store.getters["mapstate/showSoilContaminations"] || mapboxLayers.flatMap((mbl) => mbl.layer).some((layer) => this[layer.id] && layer.hasLegend)
      );
    },

    numberOfActiveMapLayers: {
      get() {
        const arrayOfLayers = [
          this.$store.getters["mapstate/showPropertyPlots"],
          this.$store.getters["mapstate/showPropertyBuildings"],
          this.$store.getters["mapstate/showOilTanks"],
          this.$store.getters["mapstate/showSoilContaminations"],
          this.$store.getters["mapstate/showPlots"],
          this.$store.getters["mapstate/showPlotNumbers"],
          this.$store.getters["mapstate/poi"],
          this.$store.getters["mapstate/building_number_label"],
          this.$store.getters["mapstate/transit"],
          this.$store.getters["mapstate/showAdvanvcedSearchResultsPins"],
          ...mapboxLayers.flatMap((mbl) => mbl.layer).map((layer) => this.$store.getters[`mapstate/${layer.id}`]),
        ];

        const result = arrayOfLayers.filter((e) => e == true);
        return result.length;
      },
    },
  },

  watch: {
    maptype(val) {
      this.$map.then((map) => {
        if (map && val === "streetview") {
          this.streetViewBounds = {
            // Conversion of Mapbox zoom to Gmaps zoom
            zoom: map.getZoom() + 1,
            center: map.getCenter(),
          };
        }

        this.$nextTick(() => {
          map?.resize();
        });
      });
    },
  },

  created() {
    this.mapIsLoading = true;
  },

  methods: {
    clickMapControlsCircle() {
      this.$map.then((map) => {
        this.mapHighlight = true;
        map.fire("draw.setDrawMode", { mode: DRAWMODE.CIRCLE });
        this.$store.commit("mapstate/setDrawingEnabled", true);

        setTimeout(() => {
          this.mapHighlight = false;
        }, 2000);
      });
    },

    clickMapControlsPolygon() {
      this.$map.then((map) => {
        this.mapHighlight = true;
        map.fire("draw.setDrawMode", { mode: DRAWMODE.POLYGON });
        this.$store.commit("mapstate/setDrawingEnabled", true);

        setTimeout(() => {
          this.mapHighlight = false;
        }, 2000);
      });
    },

    clickMapControlsClear() {
      this.$map.then((map) => {
        map.fire("draw.deleteAllDrawings");

        this.$store.commit("mapstate/setDrawingEnabled", false);

        if (this.$store.getters["mapstate/getPopupType"] == "AreaWindow") {
          this.$store.commit("mapstate/setPopupOpen", false);
        }
      });
    },

    onMapLoaded(event) {
      setTimeout(() => {
        this.mapIsLoading = false;
        this.mapIsReady = true;
        this.$map.map = event.map;

        window.dispatchEvent(new Event("resize"));
      }, 1500);

      mapSetup(event.map);

      var element = this.$refs.mapboxcontainer;

      const debounceResize = function () {
        let debounceTimer;
        return function () {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => event.map.resize(), 3);
        }.bind(this);
      }.bind(this)();

      new ResizeSensor(element, debounceResize);

      event.map.on("click", (e) => {
        const zoom = event.map.getZoom();
        const shouldZoom = event.map.queryRenderedFeatures(e.point).length == 0 || event.map.queryRenderedFeatures(e.point)[0].sourceLayer != undefined;
        const isDrawing = this.$store.getters["mapstate/getDrawingEnabled"];

        if (zoom < 17 && shouldZoom && !isDrawing) {
          event.map.flyTo({
            center: e.lngLat,
            zoom: zoom + 2,
            essential: true,
            speed: 4,
          });
        }
      });
    },

    setBounds(e) {
      if (!e) {
        return;
      }

      this.$map.then((map) => {
        map?.setZoom(e.zoom - 1);
        map?.setCenter(e.center.toJSON());
      });
      // Conversion of Gmaps zoom to Mapbox zoom
    },

    toggleLayerControls() {
      this.$store.commit("mapstate/layersMenuActive", !this.$store.getters["mapstate/layersMenuActive"]);
    },

    toggleStreetview() {
      if (this.$store.getters["mapstate/get3DViewEnabled"]) {
        this.toggle3DView();
      }

      this.maptype = this.maptype == "streetview" ? "default" : "streetview";
    },

    toggleSatteliteView() {
      if (this.$store.getters["mapstate/get3DViewEnabled"]) {
        this.toggle3DView();
      }

      this.satellite = !this.satellite;
    },

    updateExploreSearch() {
      if (this.$store.getters["explore/getQueryArray"].length > 0) {
        this.$store.commit("explore/setOffset", 0);
      }

      this.$store.commit("explore/setHasSearchedYet", false);

      if (this.$route.name == "explore-advanced") {
        this.$store.commit("explore/search");
      } else {
        this.$router.push({
          name: "explore-advanced",
          params: {},
          query: {},
        });
      }
    },

    zoomIn() {
      if (this.maptype == "streetview") {
        this.$refs.streetview.zoomIn();
      } else {
        this.$map.then((map) => {
          map?.zoomIn();
        });
      }
    },

    zoomOut() {
      if (this.maptype == "streetview") {
        this.$refs.streetview.zoomOut();
      } else {
        this.$map.then((map) => {
          map?.zoomOut();
        });
      }
    },

    windowClose() {
      if (this.$store.getters["mapstate/getPopupType"] == "AreaWindow") {
        this.clickMapControlsClear();
      }

      this.$store.commit("mapstate/setPopupOpen", false);
    },

    toggle3DView() {
      const is3DEnabled = this.$store.getters["mapstate/get3DViewEnabled"];
      this.$map.then((map) => {
        if (is3DEnabled) {
          map?.flyTo({
            pitch: 0,
            bearing: 0,
            essential: true,
            speed: 4,
            maxDuration: 0.5,
          });
        } else {
          map?.flyTo({
            pitch: 55,
            bearing: 0,
            essential: true,
            speed: 4,
            maxDuration: 0.5,
          });
        }
        this.$store.commit("mapstate/set3DViewEnabled", !is3DEnabled);
      });
    },

    openSkraafoto() {
      this.$map.then((map) => {
        if (map) {
          const center = map.getCenter();
          const utm = "+proj=utm +zone=32";
          const wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"; // Convert lat/lon to UTM
          const centerUTM = proj4(wgs84, utm, [center.lng, center.lat]);
          window.open(`https://skraafoto.dataforsyningen.dk/viewer.html?orientation=north&center=${centerUTM[0]},${centerUTM[1]}`, "_blank");
        }
      });
    },

    rotateLeft() {
      this.$map.then((map) => {
        map?.flyTo({
          bearing: map.getBearing() - 45,
          essential: true,
          speed: 4,
          maxDuration: 0.5,
        });
      });
    },

    rotateRight() {
      this.$map.then((map) => {
        map?.flyTo({
          bearing: map.getBearing() + 45,
          essential: true,
          speed: 4,
          maxDuration: 0.5,
        });
      });
    },

    download() {
      this.$map.then((map) => {
        if (map) {
          // get the map canvas
          const canvas = map.getCanvas().toDataURL("image/png");

          // create a temporary anchor element and set the download attribute
          const link = document.createElement("a");
          link.download = "Estaid.png";
          link.href = canvas;

          // append the anchor element to the body
          document.body.appendChild(link);

          // trigger a click event on the anchor element
          link.click();

          // remove the anchor element from the body
          document.body.removeChild(link);
        }
      });
    },

    showLayerTooltip(layerId, e) {
      this.$map.then((map) => {
        function getColor(s) {
          return Math.round(parseFloat(s) * 255);
        }

        const features = map.queryRenderedFeatures(e.point, { layers: [layerId] });

        if (features.length === 0) {
          return;
        }

        const layer = mapboxLayers.flatMap((mbl) => mbl.layer).find((layer) => layer.id === layerId);

        this.tooltips = features.map((f) => {
          const color = f.layer.paint["fill-color"];
          return {
            text: f.properties[layer.layerKey] || this.$t(layer.translationKey),
            color: `${getColor(color.r)}, ${getColor(color.g)}, ${getColor(color.b)}, ${color.a}`,
          };
        });

        this.popupCoordinates = [e.lngLat.lng, e.lngLat.lat];
        this.popupVisible = true;
      });
    },

    hideLayerTooltip() {
      this.popupVisible = false;
    },
  },
};
