import CreateList from "~/graphql/Lists/CreateList.gql";
import DeleteList from "~/graphql/Lists/DeleteList.gql";
import UpdateList from "~/graphql/Lists/UpdateList.gql";
import UpdateListUserPermissions from "~/graphql/Lists/UpdateListUserPermissions.gql";
import RemovePropertyFromList from "~/graphql/Lists/RemovePropertyFromList.gql";
import Lists from "~/graphql/Lists/Lists.gql";
import ListsForProperty from "~/graphql/Lists/ListsForProperty.gql";
import AddPropertyToList from "~/graphql/Lists/AddPropertyToList.gql";
import AddPropertiesToList from "~/graphql/Lists/AddPropertiesToList.gql";


// Queries
export const getListsQuery = function () {
    return {
        query: Lists,
        update(data) {
            return data;
        },
        error(error) {
            console.error("Lists overview failed:", error);
        },
    };
};

// Mutations
export const createList = function (name, description, privacy, listUserPermissions) {
    return this.$apollo
        .mutate({
            mutation: CreateList,
            variables: {
                name: name,
                description: description,
                privacy: privacy,
                listUserPermissions: listUserPermissions
            },
            update: (store, { data: { createList } }) => {
                // Read the data from our cache for this query.
                const data = structuredClone(store.readQuery({ query: Lists }));
                // Add our tag from the mutation to the end
                data.lists.push(createList);
                // Write our data back to the cache.
                store.writeQuery({ query: Lists, data });
            },
        });
};

export const updateList = function (id, name, description, privacy) {
    return this.$apollo.mutate({
        mutation: UpdateList,
        variables: {
            id: id,
            name: name,
            description: description,
            privacy: privacy
        },
    });
};

export const updateListUserPermissions = function (id, userPermissions) {
    return this.$apollo.mutate({
        mutation: UpdateListUserPermissions,
        variables: {
            listId: id,
            input: userPermissions
        },
    });
};

export const deleteList = function (id) {
    return this.$apollo.mutate({
        mutation: DeleteList,
        variables: {
            id: id,
        },
        update: (store) => {
            // Read the data from our cache for this query.
            const data = structuredClone(store.readQuery({ query: Lists }));
            // Add our tag from the mutation to the end
            data.lists = data.lists.filter(list => list.id !== id);
            // Write our data back to the cache.
            store.writeQuery({ query: Lists, data });
        },
    });
};

export const removePropertyFromList = function (id) {
    return this.$apollo.mutate({
        mutation: RemovePropertyFromList,
        variables: {
            input: id,
        },
    });
};

export const addPropertyToList = function (input) {
    return this.$apollo.mutate({
        mutation: AddPropertyToList,
        variables: {
            input,
        },
    })
};

export const addPropertiesToList = function (input) {
    return this.$apollo
        .mutate({
            mutation: AddPropertiesToList,

            variables: {
                input,
            },

            update: (store, { data: { addPropertiesToList } }) => {
                input.properties.forEach(bfeNumber => {
                    const data = structuredClone(store.readQuery({ query: ListsForProperty, variables: { bfeNumber: bfeNumber } }));

                    const listProperty = addPropertiesToList.find(p => p.property.id === bfeNumber);

                    if (data && listProperty) {
                        data.listPropertiesForProperty.push(listProperty);

                        store.writeQuery({ query: ListsForProperty, variables: { bfeNumber: bfeNumber }, data });
                    }
                })
            }
        })
        .then(() => {
            if (this.$apollo.queries.lists) {
                this.$apollo.queries.lists.refetch();
            }
            if (this.$apollo.queries.listPropertiesForProperty) {
                this.$apollo.queries.listPropertiesForProperty.refetch();
            }
        });
};

/**export const createList = function createList(name) {
    this.$apollo
        .mutate({
            mutation: CreateList,
            variables: {
                input: { name },
            },
        })
        .then(() => {
            this.$apollo.queries.lists.refetch();
        });
};
export const addPropertyToList = function addPropertyToList(id, ownersGuildCode, matrikelNumber) {
    const property = {
        listId: id,
        ownersGuildCode: ownersGuildCode,
        matrikelNumber: matrikelNumber,
    };
    const i = this.lists.findIndex((x) => x.id == id);
    if (this.lists[i].properties == null) {
        this.lists[i].properties = [];
    }
    this.lists[i].properties.push(property);

    this.$apollo
        .mutate({
            mutation: AddPropertyToList,
            variables: {
                input: property,
            },
        })
        .then(() => {
            // this.$apollo.queries.lists.refetch();
        });
};
export const removePropertyFromList = function removePropertyFromList(id, ownersGuildCode, matrikelNumber) {
    const property = {
        listId: id,
        ownersGuildCode: ownersGuildCode,
        matrikelNumber: matrikelNumber,
    };
    const i = this.lists.findIndex((x) => x.id == id);
    const j = this.lists[i].properties.findIndex((x) => x.ownersGuildCode == ownersGuildCode && x.matrikelNumber == matrikelNumber);
    this.lists[i].properties.splice(j, 1);

    this.$apollo
        .mutate({
            mutation: RemovePropertyFromList,
            variables: {
                input: property,
            },
        })
        .then(() => {
            this.$apollo.queries.lists.refetch();
        });
};
export const updateList = function updateList(id, name) {
    this.$apollo
        .mutate({
            mutation: UpdateList,
            variables: {
                input: { id, name },
            },
        })
        .then(() => {
            this.$apollo.queries.lists.refetch();
        });
};
export const deleteList = function deleteList(id) {
    return this.$apollo
        .mutate({
            mutation: DeleteList,
            variables: { input: id },
        })
        .then(() => {
            this.$apollo.queries.lists.refetch();
        });
}; */
export const getQueryVariables = function queryVariables() {
    return {
        bfeNumber: this.$store.getters["search/bfeNumber"],
    };
};
export const getSkipParameter = function skipParameter() {
    return !this.$store.getters["search/bfeNumber"];
};

export const getId = function id() {
    return { input: this.$route.params.id };
};
