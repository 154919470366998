import Vue from "vue";
import * as Sentry from "@sentry/vue";

export default (context) => {
  Sentry.init({
    Vue,
    dsn: process.env.sentryDsn,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.5,
    environment: process.env.buildVariant,
    release: process.env.version,
    logErrors: true,
  });
};
