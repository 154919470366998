// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_apo-i{display:flex;flex-direction:column;height:100%;padding:1rem;width:100%}.layout_apo-i.omitPadding_3fu4a{padding:0}.layout_apo-i.omitPaddingTop_ac2GS{padding-top:0}.layout_apo-i.omitPaddingRight_EdzUl{padding-right:0}.layout_apo-i.omitPaddingBottom_XoOno{padding-bottom:0}.layout_apo-i.omitPaddingLeft_Dyy7U{padding-left:0}.layout_apo-i.paddingDouble_oe9ow{padding-bottom:1.5rem;padding-top:1.5rem}.header_0eu0w{flex-direction:row;justify-content:space-between;position:relative}.header_0eu0w,.texts_wUaBX{display:flex;width:100%}.texts_wUaBX{flex-direction:column;margin-bottom:.5rem}.justifyTextsCenter_qnv8\\+{justify-content:center}.byline_C8iHK,.label_-H5oL{display:flex;flex-direction:row;margin-bottom:.5rem;width:100%}.textsOmitPaddingBottom_wLBt5{margin-bottom:0}.slot_X8ysB{display:flex;flex-direction:column;flex-grow:1}.slot_X8ysB.row_nBdmA{flex-direction:row}.slot_X8ysB.justifyContentCenter_j3KEJ{justify-content:center}.slot_X8ysB.justifyContentEnd_8Tt2d{justify-content:flex-end}.slot_X8ysB.alignItemsCenter_EuPU8{align-items:center}.slot_X8ysB.alignItemsEnd_eKk2h{align-items:flex-end}.slot_X8ysB.omitPaddingBottomClickMore_AwzdA,.slot_X8ysB.omitPaddingContent_FVP60{transform:translateX(-1rem);width:calc(100% + 2rem)}.slot_X8ysB.omitPaddingBottomClickMore_AwzdA{outline:2px solid red}.cta_UCEi8{display:flex;flex-direction:column;height:100%;justify-content:flex-end;padding-top:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "layout_apo-i",
	"omitPadding": "omitPadding_3fu4a",
	"omitPaddingTop": "omitPaddingTop_ac2GS",
	"omitPaddingRight": "omitPaddingRight_EdzUl",
	"omitPaddingBottom": "omitPaddingBottom_XoOno",
	"omitPaddingLeft": "omitPaddingLeft_Dyy7U",
	"paddingDouble": "paddingDouble_oe9ow",
	"header": "header_0eu0w",
	"texts": "texts_wUaBX",
	"justifyTextsCenter": "justifyTextsCenter_qnv8+",
	"byline": "byline_C8iHK",
	"label": "label_-H5oL",
	"textsOmitPaddingBottom": "textsOmitPaddingBottom_wLBt5",
	"slot": "slot_X8ysB",
	"row": "row_nBdmA",
	"justifyContentCenter": "justifyContentCenter_j3KEJ",
	"justifyContentEnd": "justifyContentEnd_8Tt2d",
	"alignItemsCenter": "alignItemsCenter_EuPU8",
	"alignItemsEnd": "alignItemsEnd_eKk2h",
	"omitPaddingBottomClickMore": "omitPaddingBottomClickMore_AwzdA",
	"omitPaddingContent": "omitPaddingContent_FVP60",
	"cta": "cta_UCEi8"
};
module.exports = ___CSS_LOADER_EXPORT___;
