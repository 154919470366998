import { mapboxLayers } from "../components/map/mapboxLayers";

export enum NumberDisplay {
  Absolute = "ABSOLUTE",
  Thousands = "THOUSANDS",
  Millions = "MILLIONS",
}

export type NamespaceChrome = {
  splitPercentage: number;
  containerFocusIndex: number;
};

export const DEFAULTS = {
  preferenceNamespaceTableBuildings: {
    buildingNumber: true,
    address: true,
    matEjerlav: false,
    usageText: true,
    statusText: false,
    constructionYear: true,
    conservationText: false,
    noOfFloors: false,
    energyLabel: false,
    heatingInstallationText: false,
    waterSupplyText: false,
    drainageConditionText: false,
    roofingMaterialText: false,
    outerWallMaterialText: false,
    builtArea: false,
    totalAreaOfClosedShelter: false,
    buildingArea: true,
    totalBasementArea: true,
    usedBasementArea: false,
    deepBasementArea: false,
    totalAtticArea: true,
    usedAtticArea: false,
    totalOtherArea: false,
    totalResidentialArea: true,
    legalBasementResidence: false,
    totalCommercialArea: false,
    commercialBasementArea: false,
    floorsWithoutBasementAndAttic: false,
  },
  preferenceNamespaceTableCadastrals: {
    matEjerlav: true,
    status: true,
    registeredArea: true,
    preservedForestArea: true,
    noOfBuildings: true,
    noOfUnits: true,
    noOfTechnicalFacilities: true,
  },
  preferenceNamespaceTableUnits: {
    address: true,
    matEjerlav: false,
    buildingNumber: false,
    usageText: true,
    statusText: true,
    numberOfBathrooms: false,
    bathroomConditionText: false,
    numberOfWaterFlushingToilets: false,
    toiletConditionText: false,
    kitchenConditionText: false,
    residentialArea: true,
    commercialArea: true,
    openShelterArea: false,
    enclosedShelterOuthouseArea: false,
    otherArea: false,
    dlrArea: false,
    numberOfRooms: false,
    totalArea: true,
  },
  preferenceNamespaceTableTechnicalFacilities: {
    facilityNumber: true,
    classificationText: true,
    matEjerlav: false,
    constructionYear: true,
    oilTankContentText: false,
    locationText: false,
    statusText: true,
    oilTankMaterialText: false,
    oilTankSizeClassText: false,
    oilTankSize: false,
    manufacturingYear: false,
  },
  preferenceNamespaceTableCondos: {
    address: true,
    bfeNumber: true,
    condoNumber: true,
    usageText: false,
    ownersString: false,
    valuation: true,
  },
  preferenceNamespaceTablePostAddresses: {
    address: true,
    doorNumber: true,
    postalCode: true,
  },
  preferenceNamespaceTableLists: {
    address: true,
    description: true,
    status: true,
    owner: true,
    updatedAt: true,
  },
  preferenceNamespaceLoginMessages: {
    plesnerPersonLookupMessage: false,
  },
  preferenceNamespaceLocale: {
    locale: "da-dk",
  },
  preferenceNamespaceMap: {
    showPropertyPlots: true,
    showPropertyBuildings: true,
    showOrtoForaar: false,
    showSoilContaminations: false,
    showOilTanks: false,
    showPlots: true,
    showPlotNumbers: true,
    oilTanksPlacementFilter: null,
    oilTanksDisamblementFilter: null,
    poi: false,
    transit: false,
    satellite: false,
    building_number_label: false,
    ...mapboxLayers
      .flatMap((mbl) => mbl.layer as any)
      .reduce((acc, layer) => {
        acc[layer.id] = false;
        return acc;
      }, {} as any /**TODO should be typed */),
  },
  preferencesNamespaceChrome: <NamespaceChrome>{
    containerFocusIndex: 1,
    splitPercentage: 65,
  },
  preferenceNamespaceLists: {
    listView: "list",
  },
  preferenceNamespaceTableLejetjekProperty: {
    type: true,
    totalYearlyTopRent: false,
    totalYearlyAvgRent: false,
    totalYearlyBottomRent: false,
    totalYearlyBottomSquareMeterRent: true,
    totalYearlyAvgSquareMeterRent: true,
    totalYearlyTopSquareMeterRent: true,
    vacancyRate: true,
    vacancyDescription: true,
  },
} as const;

export type NamespaceName = keyof typeof DEFAULTS;
export type Namespace = (typeof DEFAULTS)[NamespaceName];

export const matchNamespace = <T extends NamespaceName>(namespace: NamespaceName): (typeof DEFAULTS)[T] => {
  const matchedNamespace = DEFAULTS[namespace];

  if (matchedNamespace === undefined) {
    throw `~/plugins/user-preferences.js - namespace: "${namespace}" not defined`;
  }

  return matchedNamespace;
};

export const getNamespaceSync = <T extends NamespaceName>(namespace: T, preferences: any): Mutable<(typeof DEFAULTS)[T]> => {
  const matchedNamespace = matchNamespace(namespace);

  if (preferences === undefined || preferences[namespace] === undefined) {
    return matchedNamespace;
  } else {
    const output = {} as typeof matchedNamespace;
    const preferencesNamespaced = preferences[namespace];

    Object.keys(matchedNamespace).forEach((key: keyof typeof matchedNamespace) => {
      if (preferencesNamespaced[key] === undefined) {
        output[key] = matchedNamespace[key];
      } else {
        output[key] = preferencesNamespaced[key];
      }
    });

    return output;
  }
};
