import { getPropertyCentroid, hasPropertyCentroid } from "~/helpers/property-helpers";

import { i18n } from "~/plugins/translator";

const intl = new Intl.NumberFormat(i18n.locale, {
  style: "currency",
  currency: "DKK",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const labelRentFrom = i18n.t("LOKALEBASEN_MAP_LABEL_RENT_FROM");

const purple900 = getComputedStyle(document.documentElement).getPropertyValue("--color-purple-900").replace(" ", "");
const test = getComputedStyle(document.documentElement).getPropertyValue("--color-pink-900").replace(" ", "");
const yellow900 = getComputedStyle(document.documentElement).getPropertyValue("--color-yellow-900").replace(" ", "");
const black900 = getComputedStyle(document.documentElement).getPropertyValue("--color-black-900").replace(" ", "");
const black300 = getComputedStyle(document.documentElement).getPropertyValue("--color-black-300").replace(" ", "");

export const mapSetup = function (map) {
  ["property", "unit", "facility", "building", "cluster", "bg_black_50", "bg_black_100"].forEach((image) => {
    map.loadImage(`/mapmarkers/${image}.png`, (err, img) => {
      if (err) throw err;
      map.addImage(image, img);
    });
  });

  // BEWARE: Ordering is important
  const layers = [
    plot_polygon_matrikel_numbers, // matrikel numbers
    plot_polygon_borders, // matrikel outlines
    plot_polygon_fills, // matrikel hover effect
    property_plot_polygon_border, // selected matrikel outlines
    property_plot_polygon_fill, // selected matrikel fill color
    // drawing layers are placed here, see MapDrawManager.vue -> addDrawLayer()
    explore_property_pins, // property pins
    msga_property_pins, // property pins
    reference_property_pins, // property pins
    list_property_pins, // list entities
    property_pins, // building pins
    rent_level_pins, // rent-level pins
    company_pins,
    person_pins,
  ];

  const sourcesWithClustering = ["explore-properties", "list", "property", "rent-levels", "company", "person", "reference-properties"];

  map.addSource("orto_foraar-source", {
    tileSize: 256,
    tiles: [
      "https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar/1.0.0/WMS?&service=WMS&request=GetMap&layers=orto_foraar&styles=&format=image/png&TRANSPARENT=TRUE&Version=1.3.0" +
        `&username=${process.env.datafordelerUser}&password=${process.env.datafordelerPassword}` +
        "&crs=EPSG%3A3857&bbox={bbox-epsg-3857}&width=256&height=256",
    ],
    type: "raster",
  });

  map.addLayer({
    id: "orto_foraar-layer",
    type: "raster",
    source: "orto_foraar-source",
  });

  layers.forEach((layer) => {
    if (!map.getSource(layer.source)) {
      map.addSource(layer.source, sourcesWithClustering.includes(layer.source) ? emptyGeoJsonSourceWithClusters : emptyGeoJsonSource);
    }

    map.addLayer(layer);
  });
};

const clusterStyleBuilder = (icon) => {
  return {
    layout: {
      // "text-font": ["Gerstner Programm FSL Bold"],
      "text-field": ["case", ["has", "point_count"], ["get", "point_count"], ""],
      "text-size": 14,
      "text-font": ["Roboto Mono Bold"],
      "icon-image": ["case", ["has", "point_count"], "cluster", icon],
      "icon-size": 1,
      "icon-anchor": ["case", ["has", "point_count"], "center", "bottom"],
      "icon-allow-overlap": true,
      "symbol-z-order": "viewport-y",
    },

    paint: {
      "icon-opacity": 1,
      "icon-opacity-transition": {
        duration: 1000,
      },

      "text-color": "#fff",
    },
  };
};

const labelStyleBuilder = (icon) => {
  return {
    layout: {
      "text-field": ["get", "rent_level"],
      "text-rotation-alignment": "auto",
      "text-allow-overlap": false,
      "text-anchor": "top",
      "text-size": 13,
      "text-font": ["Roboto Mono Bold"],
      "text-justify": "center",
      "icon-image": icon,
      "icon-text-fit": "both",
      "icon-text-fit-padding": [4, 4, 4, 4],
    },

    paint: {
      "text-color": black900,
      "icon-opacity": ["case", ["has", "rent_level"], 1, 0],
    },
  };
};

const backendClusterStyleBuilder = (icon) => {
  return {
    layout: {
      // "text-font": ["Gerstner Programm FSL Bold"],
      "text-field": ["case", [">", ["get", "point_count"], 1], ["number-format", ["get", "point_count"], { locale: i18n.locale }], ""],
      "text-size": 13,
      "text-font": ["Roboto Mono Bold"],
      "icon-image": ["case", [">", ["get", "point_count"], 1], "cluster", icon],
      "icon-size": 1,
      "icon-anchor": ["case", ["has", "point_count"], "center", "bottom"],
      "icon-allow-overlap": true,
      "symbol-z-order": "viewport-y",
    },

    paint: {
      "icon-opacity": 1,
      "icon-opacity-transition": {
        duration: 1000,
      },
      "text-color": "#fff",
      "text-halo-color": purple900,
      "text-halo-width": 2,
      "text-halo-blur": 10,
    },
  };
};

export const emptyGeoJson = {
  type: "FeatureCollection",
  features: [],
};

export const emptyGeoJsonSource = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: [],
  },
  generateId: true,
};

export const emptyGeoJsonSourceWithClusters = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: [],
  },
  generateId: true,
  cluster: true,
  clusterMaxZoom: 14,
  clusterRadius: 60,
};

export const plot_polygon_borders = {
  id: "plot_polygon_borders",
  minzoom: 14,
  type: "line",
  source: "common-plots",
  paint: {
    "line-color": black300,
    "line-width": 1,
  },
};

export const plot_polygon_fills = {
  id: "plot_polygon_fills",
  minzoom: 14,
  type: "fill",
  source: "common-plots",
  paint: {
    "fill-color": black900,
    "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.2, 0],
  },
};

export const plot_polygon_matrikel_numbers = {
  id: "plot_polygon_matrikel_numbers",
  type: "symbol",
  minzoom: 14,
  source: "common-plots",
  layout: {
    "text-field": ["get", "MATRNR"],
    "text-font": ["Roboto Mono Bold"],
    "text-variable-anchor": ["center"],
    "text-justify": "center",
    "text-size": 14,
  },
  paint: {
    "text-color": black900,
  },
};

export const property_plot_polygon_fill = {
  id: "property_plot_polygon_fill",
  type: "fill",
  source: "property-plot",
  paint: {
    "fill-color": test,
    "fill-opacity": 0,
  },
};

export const property_plot_polygon_border = {
  id: "property_plot_polygon_border",
  type: "line",
  source: "property-plot",
  paint: {
    "line-color": purple900,
    "line-width": 4,
  },
};

export const explore_property_pins = {
  id: "explore_property_pins",
  type: "symbol",
  source: "explore-properties",
  ...clusterStyleBuilder("property"),
};

export const msga_property_pins = {
  id: "msga_property_pins",
  type: "symbol",
  source: "msga-properties",
  ...backendClusterStyleBuilder("property"),
};

export const reference_property_pins = {
  id: "reference_property_pins",
  type: "symbol",
  source: "reference-properties",
  ...clusterStyleBuilder("property"),
};

export const list_property_pins = {
  id: "list_property_pins",
  type: "symbol",
  source: "list",
  ...clusterStyleBuilder(["get", "icon"]),
};

export const company_pins = {
  id: "company_pins",
  type: "symbol",
  source: "company",
  ...clusterStyleBuilder(["get", "icon"]),
};

export const person_pins = {
  id: "person_pins",
  type: "symbol",
  source: "person",
  ...clusterStyleBuilder(["get", "icon"]),
};

export const property_pins = {
  id: "property_pins",
  type: "symbol",
  source: "property",
  ...clusterStyleBuilder(["get", "icon"]),
};

export const rent_level_pins = {
  id: "rent_level_pins",
  type: "symbol",
  source: "rent-levels",
  ...labelStyleBuilder("bg_black_100"),
};

export const formatBuildingMarker = function (data) {
  if (!data || !data.plots) {
    return [];
  }

  const result = data.plots
    .flatMap((x) => {
      return x.buildings;
    })
    .filter((x) => {
      return x && x.longitude && x.latitude;
    })
    .sort((a, b) => {
      return b.latitude - a.latitude;
    })
    .map((x) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [x.longitude, x.latitude],
        },
        properties: {
          type: "building",
          icon: "building",
          buildingNumber: x.buildingNumber,
          id: x.id,
        },
      };
    });

  return result;
};

export const formatBOFPBuildingMarkers = function (data) {
  if (!data || data.buildingOnForeignPlot?.buildings == null || data.buildingOnForeignPlot.buildings.length === 0) {
    return [];
  }

  const result = data.buildingOnForeignPlot.buildings.map((x) => {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [x.longitude, x.latitude],
      },
      properties: {
        type: "buildingOnForeignPlot",
        icon: "building",
        buildingNumber: x.buildingNumber,
        id: x.id,
      },
    };
  });

  return result;
};

export const formatBOFPMarkers = function (data) {
  if (!data || data.length == 0) {
    return [];
  }

  const result = data
    .filter((x) => hasPropertyCentroid(x))
    .map((x) => {
      const centroid = getPropertyCentroid(x);
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [centroid.lng, centroid.lat],
        },
        properties: {
          type: "buildingOnForeignPlot",
          icon: "building",
          id: x.id,
          bfe: x.bfeNumber,
        },
      };
    });
  return result;
};

export const formatFacilityMarker = function (data) {
  if (!data || !data.plots) {
    return [];
  }

  const result = data.plots
    .flatMap((x) => {
      return x.technicalFacilities;
    })
    .filter((x) => {
      return x && x.longitude && x.latitude;
    })
    .sort((a, b) => {
      return b.latitude - a.latitude;
    })
    .map((x) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [x.longitude, x.latitude],
        },
        properties: {
          type: "facility",
          icon: "facility",
          id: x.id,
          demolished: !!x.demolitionYear,
          demolitionYear: x.demolitionYear,
          locationCode: x.locationCode,
        },
      };
    });

  return result;
};

export const formatPropertyPolygon = function (data) {
  if (!data || !data.plots || !data.plots.some((x) => x?.geometry?.polygon)) {
    return emptyGeoJson;
  }

  const result = {
    type: "FeatureCollection",
    features: [
      ...data.plots
        .filter((x) => x.geometry?.polygon)
        .map((y) => {
          return {
            type: "Feature",
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  ...y.geometry.polygon.map((z) => {
                    return z.map((p) => {
                      return [p.lng, p.lat];
                    });
                  }),
                ],
              ],
            },
            properties: {
              matrikelNumber: y.matrikelNumber,
            },
          };
        }),
    ],
  };

  return result;
};

export const formatPropertyMarkers = function (data) {
  if (!data) {
    return [];
  }
  const result = data
    .filter((x) => hasPropertyCentroid(x))
    .map((x) => {
      const centroid = getPropertyCentroid(x);
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [centroid.lng, centroid.lat],
        },
        properties: {
          type: "property",
          icon: "property",
          bfe: x.bfeNumber,
        },
      };
    });
  return result;
};

export const formatRenLevelMarkers = function (data) {
  if (!data) {
    return [];
  }

  return data.map((r) => {
    const rentLevel = `${labelRentFrom} ${intl.format(r.yearlyRentPerM2AmountFrom)}`;

    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [r.longitude, r.latitude],
      },
      properties: {
        type: "rent-levels",
        rent_level: rentLevel,
      },
    };
  });
};

export const formatCondoMarkers = function (data) {
  if (!data) {
    return [];
  }

  const result = data
    .filter((x) => hasPropertyCentroid(x))
    .map((x) => {
      const centroid = getPropertyCentroid(x);
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [centroid.lng, centroid.lat],
        },
        properties: {
          type: "condo",
          icon: "unit",
          bfe: x.condo?.bfeNumber,
        },
      };
    });

  return result;
};

export const formatCondoMarker = function (data) {
  if (!data || !data.condo) {
    return emptyGeoJson;
  }

  const centroid = getPropertyCentroid(data);

  if (centroid != null) {
    const result = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [centroid.lng, centroid.lat],
      },
      properties: {
        type: "condo",
        icon: "unit",
        bfe: data.condo?.bfeNumber,
      },
    };

    return result;
  } else {
    return emptyGeoJson;
  }
};

export const formatExploreResults = function (data) {
  if (!data || data.length == 0) {
    return emptyGeoJson;
  }

  const result = {
    type: "FeatureCollection",
    features: [
      ...data.map((x) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [x.lng, x.lat],
          },
          properties: {
            type: "property",
            icon: "property",
            bfe: x.bfeNumber,
          },
        };
      }),
    ],
  };

  return result;
};

// {
//   "geometry": {
//       "type": "Point",
//       "coordinates": [
//           12.531967163085938,
//           55.708158452041545
//       ]
//   },
//   "type": "Feature",
//   "properties": {
//       "cluster": true,
//       "cluster_id": 12,
//       "point_count": 3,
//       "point_count_abbreviated": 3
//   },
//   "id": 12,
//   "tile": {
//       "z": 6,
//       "x": 34,
//       "y": 20
//   }
// }

export const formatMSGAClusters = function (data) {
  if (!data || data.length == 0) {
    return emptyGeoJson;
  }

  const result = {
    type: "FeatureCollection",
    features: [
      ...data.map((x) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [x.lon, x.lat],
          },
          properties: {
            point_count: x.count,
          },
        };
      }),
    ],
  };

  return result;
};

export const formatListResults = function (data) {
  if (!data || data.length == 0) {
    return emptyGeoJson;
  }

  const result = {
    type: "FeatureCollection",
    features: [
      ...data.map((x) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [x.lng, x.lat],
          },
          properties: {
            type: "property",
            icon: "property",
            bfe: x.bfeNumber,
          },
        };
      }),
    ],
  };

  return result;
};

export const formatReferenceResults = function (data) {
  if (!data || data.length == 0) {
    return emptyGeoJson;
  }

  const result = {
    type: "FeatureCollection",
    features: [
      ...data.map((x) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [x.lng, x.lat],
          },
          properties: {
            type: "property",
            icon: "property",
            bfe: x.bfeNumber,
          },
        };
      }),
    ],
  };
  return result;
};
