import Vuex from "vuex";
import msga from "./msga";
import mapstate from "./mapstate";
import explore from "./explore";
import search from "./search";
import list from "./lists";
import reference from "./reference.js";
import fremium from "./fremium";
import sortableTable from "./sortableTable";
import areas from "./areas";
import pagevisit from "./pagevisit";
import templateBuilder from "./templateBuilder";

export const store = new Vuex.Store({
  modules: {
    msga: msga,
    search: search,
    mapstate: mapstate,
    explore: explore,
    list: list,
    reference: reference,
    fremium: fremium,
    sortableTable,
    areas: areas,
    pagevisit: pagevisit,
    templateBuilder: templateBuilder,
  },
});
