import { defineStore } from "pinia";
import { useAmplitudeStore } from "./amplitude";
export var useUserStore = defineStore("user", function () {
  var ampliStore = useAmplitudeStore();
  var user = ref();
  watchEffect(function () {
    if (user.value) {
      ampliStore.identify(user.value);
    }
  });
  return {
    user: user
  };
});