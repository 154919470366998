import { ampli } from "~/ampli/index";
var loadPromise = ampli.load({
  environment: "production",
  client: {
    apiKey: process.env.amplitudeKey,
    configuration: {
      defaultTracking: false,
      serverUrl: "https://collect.estaid.dk/2/httpapi"
    }
  }
}).promise;
export default (function (context, inject) {
  inject("amplitude", ampli);
});
export { ampli, loadPromise };