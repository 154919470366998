// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_lRInH{display:flex;flex-direction:column;margin-bottom:1px}.container_lRInH:last-child{margin-bottom:0}.widget_tLAIT{display:flex;flex-direction:row;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_lRInH",
	"widget": "widget_tLAIT"
};
module.exports = ___CSS_LOADER_EXPORT___;
